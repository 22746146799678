@import "../../../variabels.scss";
.AdvisorPeopleCard {
  height: 500px;
  width: 250px;
  padding-left: 8px;
  padding-right: 8px;

  .img-container {
    width: 100%;
    height: 315px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .data {
    overflow: hidden;
    padding-top: 20px;
    .name {
      font-family: "Cormorant Garamond";
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .descriptions {
      font-family: GothamLight;
      font-weight: bold;
      font-size: 11px;
      line-height: 18px;
      p {
        margin: 0;
        margin-bottom: 0.4em;
      }
    }
  }

  @include phone {
    height: auto;
    width: 100%;
    margin-bottom: 70px;
    padding: 0;
    .img-container {
      height: auto;
    }
  }
}
