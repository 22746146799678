@import "../../../variabels.scss";

.Navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $navbarHeight;
  padding: 0 5px;
  z-index: 9999999;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .logo-container {
    margin-left: 20px;

    .img-link:active {
      background: none;
      user-select: none;
    }
    img {
      height: 70px;
    }
  }

  .links-container {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-right: 40px;

    .link {
      display: flex;
      position: relative;
      align-items: center;
      margin-right: 2.5em;
      font-family: "GothamLight";
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      height: calc(#{$navbarHeight} * 0.6);
      cursor: pointer;
      color: #222222;
      text-decoration: none;
      transition: all 0.3s;

      &.active {
        font-weight: bold;
        font-family: "GothamMedium";

        &::before {
          content: "";
          height: 5px;
          width: 50px;
          background: #adc8c2;
          position: absolute;
          bottom: 0;
        }
      }

      .dropdown {
        position: absolute;
        top: calc((#{$navbarHeight} * 0.6) - 5px);
        display: flex;
        flex-direction: column;
        height: 0;
        background: #ffffff;
        border-radius: 2px;
        overflow-y: hidden;
        padding: 0;
        width: 160px;
        z-index: 9999;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

        &.open {
          height: auto;
          padding: 3px 0;
        }

        .dropdown-link {
          text-decoration: none;
          color: #666666;
          font-family: GothamLight;
          font-weight: bold;
          padding: 3px 10px;
          font-size: 11px;
          letter-spacing: 0.5px;
          transition: all 0.3s;

          &:hover {
            background: #f3f3f3;
          }

          &.active {
            font-family: GothamMedium;
            box-shadow: 5px 0 0 #aaaaaa inset;
            padding-left: 15px;
            background: #f3f3f3;
          }
        }
      }
    }
  }

  .links-container-menu-btn {
    display: none;
    width: 30px;
    height: 30px;
    padding: 0 5px;
    margin-right: 30px;
    border-radius: 3px;
    border: 1px solid #222;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .next{
    color: #ff7518;
  }

  @include large-down {
    .logo-container {
      margin-left: 20px;

      img {
        height: 65px;
      }
    }
    .links-container {
      display: none;

      &.phone-view {
        position: fixed;
        top: $navbarHeight;
        left: 0;
        width: 100%;
        height: auto;
        background: #ffffff;
        border-top: 1px solid #aaaaaa;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

        .Link {
          width: 100%;
        }
        .link {
          flex: 1;
          text-transform: uppercase;
          text-decoration: none;
          color: #222;
          width: 100%;
          padding: 10px 15px;
          height: auto;
          width: 100%;

          &.active {
            background: #eeeeee;
            font-weight: bold;
          }

          &::before {
            content: "";
            display: none;
          }

          .dropdown {
            top: 40px;
            width: 80%;
            z-index: 9999;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

            &.open {
              height: auto;
              padding: 3px 0;
            }

            .dropdown-link {
              text-decoration: none;
              color: #666666;
              font-family: GothamLight;
              font-weight: bold;
              padding: 3px 10px;
              font-size: 11px;
              letter-spacing: 0.5px;
              transition: all 0.3s;

              &:hover {
                background: #f3f3f3;
              }

              &.active {
                font-family: GothamMedium;
                box-shadow: 5px 0 0 #aaaaaa inset;
                padding-left: 15px;
                background: #f3f3f3;
              }
            }
          }
        }
      }
    }
    .links-container-menu-btn {
      display: flex;
    }
  }

  @include phone {
    .logo-container {
      img {
        height: 60px;
      }
    }
  }
}
