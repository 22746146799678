// src/components/pages/ertnext/scss/ERTNext.scss
.ERTNext {
    // background-color: #36454F;
    color: white;
  }
  
  .ertnext-text{
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    font-family: "GothamLight";
    color: #36454F;
  }
  
    .mt-5 {
      margin-top: 50px;
    }
  
  .page-section-auto {
    padding: 50px 0;
  }
  
  .ert-blackish-background {
    background-color: rgba(211, 211, 211, 0.2);
  }

  .ertnext-text-img-1{
    width: 400px;
    align-self: center;
  }

  .ertnext-text-img-2{
    width: 500px;
    object-fit: contain;
    align-self: center;
  }
  
  .ert-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding-top: 50px;
    min-height: 83.4vh;
  }
  
  .h-gap {
    height: 30px;
  }
  
  h1 {
    text-align: center;
    font-size: 3rem;
  }
  
  @media screen and (max-width: 768px) {
    .ertnext-text-img-1{
      width: 70%;
    }
    .ertnext-text-img-2{
      width: 80%;
    }
    
  }