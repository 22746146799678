@import "../../../../variabels.scss";

.Home {
  height: calc(100vh - #{$navbarHeight});
  background: linear-gradient(45deg, $blackishFrom, $blackishTo);

  .home-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    .background-logo {
      // position: absolute;

      height: 100vmin;
      max-height: calc(100vh - 90px);
    }

    .home-text-img-container {
      position: absolute;
      .home-text-img {
        height: 75vmin;
        max-height: calc(75vh - 90px);
      }
    }
  }
}
