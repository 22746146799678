@import "../../../../variabels.scss";

.Contact {
  color: #ffffff;
  min-height: 380px;
  color: #aaa;

  .SectionHeader {
    .bar {
      background: #aaa;
    }
  }

  .body {
    .title {
      font-family: "GothamMedium";
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .address {
      font-family: "GothamLight";
      font-weight: bold;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      a {
        color: #aaa;
        text-decoration: none;
      }
    }

    .bottom-link {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      font-family: "GothamLight";
      font-weight: bold;
      font-style: normal;
      font-size: 12px;
      line-height: 24px;

      > span {
        flex: 1;
        min-width: 150px;

        a {
          color: #aaa;
          text-decoration: none;
        }
      }
      .copyright {
      }
    }
  }
}
