@import "../../../../variabels.scss";

.Membership {
  margin-bottom: 100px;
  .body {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 40px;

    .left {
      .member-text-img-1 {
        width: 45vw;
        max-width: 550px;
      }
    }
    .space {
      width: 120px;
      min-width: 120px;
      height: 120px;
    }
    .right {
      margin-bottom: 15px;
      margin-right: 20px;
      .member-text-img-2 {
        width: 40vw;
        max-width: 430px;
      }
    }
  }

  @include medium-down {
    .body {
      flex-direction: column;
      align-items: flex-start;

      .left {
        .member-text-img-1 {
          height: auto;
          width: 100%;
          max-width: none;
        }
      }

      .right {
        margin-top: 80px;
        margin-right: 0;
        .member-text-img-2 {
          height: auto;
          width: 100%;
          max-width: none;
        }
      }
    }
  }
  @include phone {
    .body {
      margin-top: 0;
    }
  }
}
