@import "../../../variabels.scss";

.SectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 20px;

  .title {
    font-family: GothamBold;
    font-size: 27px;
    line-height: 35px;

    text-transform: uppercase;
    align-items: center;
  }

  .bar {
    margin-top: 15px;
    width: 60px;
    height: 5px;
    background: #000;
  }
}
