@import "../../../../variabels.scss";

.Testimonials {
  margin-top: 80px;
  margin-bottom: 50px;
  .body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .card {
      flex: 1;
      flex-basis: 50%;
      margin-bottom: 50px;

      .AboutPeopleCard {
        .img-area {
          .img-container {
            img {
              height: 258px;
            }
          }
        }
      }
    }
  }

  @include phone {
    .body {
      .card {
        .AboutPeopleCard {
          .img-area {
            .img-container {
              img {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
