@import "../../../../variabels.scss";
.Founders {
  .founder-section {
    .common-text {
      font-size: 13px;
      line-height: 25px;
      font-family: GothamLight;
      font-weight: bold;
      a {
        font-size: 13px;
        line-height: 25px;
        font-family: GothamLight;
        font-weight: bold;
        text-decoration: none;
        color: #222;
      }
    }
    .body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .AboutPeopleCard {
        .img-area {
          .img-container {
            margin-bottom: 15px;
          }
          .about {
            .name {
              font-family: "Cormorant Garamond";
              font-weight: bold;
              font-size: 22px;
              line-height: 24px;
              margin-bottom: 5px;
            }

            .designations {
              font-family: GothamLight;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 16px;
              p {
                margin: 0;
              }
            }
            .email-container {
              margin-bottom: 20px;
              .email {
                font-family: GothamLight;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                text-decoration: none;
                color: #000;
              }
            }
          }
        }

        .data {
          .testimonial {
            p {
              margin-top: -4px;
              line-height: 21px;
            }

            @include phone {
              margin-top: 15px;
            }
          }
        }

        @include phone {
          margin-top: 50px;
        }
      }
    }
  }

  .co-founder-section {
    margin-top: 50px;
    .body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .side {
        width: 50%;
        @include phone {
          width: 100%;
        }

        .AboutPeopleCard {
          .img-area {
            .img-container {
              img {
                height: 258px;
                @include phone {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
