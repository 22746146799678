@import "../../../variabels.scss";

.MainPage {
  .page-padding {
    height: $navbarHeight;
  }
  .router-pages {
    .target-link {
      visibility: hidden;
      position: relative;
      top: -$navbarHeight;
    }
  }
}
