@import "./variabels.scss";

@font-face {
  font-family: "Gotham";
  src: url("//db.onlinewebfonts.com/t/bd317adf74e5e7e564eb61ce9926cc6d.eot");
  src: url("//db.onlinewebfonts.com/t/bd317adf74e5e7e564eb61ce9926cc6d.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/bd317adf74e5e7e564eb61ce9926cc6d.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/bd317adf74e5e7e564eb61ce9926cc6d.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/bd317adf74e5e7e564eb61ce9926cc6d.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/bd317adf74e5e7e564eb61ce9926cc6d.svg#Gotham") format("svg");
}

@font-face {
  font-family: "GothamBold";
  src: url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.eot");
  src: url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/5d6f50ed2dde0e4ad455448f896c09f0.svg#Gotham") format("svg");
}

@font-face {
  font-family: "GothamMedium";
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot");
  src: url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/3a47f5f06b5484abfeee9eac90348a9c.svg#Gotham") format("svg");
}

@font-face {
  font-family: "GothamLight";
  src: url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.eot");
  src: url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/fc74760c72164d5321ac886797ce62eb.svg#Gotham") format("svg");
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "GothamLight", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page-section {
  height: calc(100vh - #{$navbarHeight});
  padding: 0 20px;
}

.page-section-auto {
  height: auto;
  // min-height: calc(100vh - #{$navbarHeight});
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1336px;
  }
}

.catch-link {
  height: 0;
  max-height: 0;
  position: relative;
  top: -$navbarHeight;
}
.catch-link-founder {
  height: 0;
  max-height: 0;
  position: relative;
  top: calc(#{-$navbarHeight} - 50px);
}

.h-gap {
  height: 50px;
}
.h4-gap {
  height: 200px;
}

.italic {
  font-style: italic;
}

.blackish-background {
  background: linear-gradient(90deg, $blackishFrom, $blackishTo);
}

@keyframes scaleDownIn {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleUpIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
