@import "../../../variabels.scss";

.AboutPeopleCard {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 35px 0;
  flex-wrap: wrap;

  .img-area {
    .img-container {
      margin-right: 30px;
      margin-bottom: 30px;

      img {
        height: 338px;
      }
    }

    .linkedin-icon {
      width: 40px;
      margin-bottom: 10px;
    }
  }

  .data {
    flex: 1;
    margin-right: 38px;
    max-width: 330px;
    margin-left: 0;

    .bar {
      width: 44px;
      height: 8px;
      background: rgb(178, 199, 194);
      margin-bottom: 20px;
      // margin-top: 30px;
    }

    .name {
      font-family: "Cormorant Garamond";
      font-weight: bold;
      font-size: 22px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    .designations {
      font-family: GothamLight;
      font-style: normal;
      font-weight: bold;
      font-size: 11.5px;
      line-height: 16px;
      p {
        margin: 0;
        margin-bottom: 0.4em;
      }
    }
    .member-since {
      font-family: GothamLight;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      margin-top: 0;
    }
    .email {
      font-family: GothamLight;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
      color: #000;
    }

    .testimonial {
      font-family: GothamLight;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 18px;
    }
  }

  @include large-up {
    flex-direction: row;
  }
  @include phone {
    .img-area {
      .img-container {
        margin-right: 0;
        text-align: center;
        img {
          height: auto;
          width: 90vw;
        }
      }
    }
    .data {
      min-width: 90vw;
      margin-right: 0;
      margin-left: 4px;
    }
  }
}
